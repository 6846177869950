import * as THREE from 'three'
import * as dat from 'lil-gui'
import gsap from 'gsap'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'

THREE.ColorManagement.enabled = false

/**
 * Debug
 */
const gui = new dat.GUI()

gui.domElement.style.display = 'none';  // to hide

    
/**
 * Base
 */

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * HDR
 */
const rgbeLoader = new RGBELoader()

rgbeLoader.load(
    '/environmentMaps/empty_warehouse_01_1k.hdr', (environmentMap) => {
        console.log(environmentMap);
    }
)

const hdrEquirect = new RGBELoader().load(
    "/environmentMaps/empty_warehouse_01_1k.hdr",
    () => {
      hdrEquirect.mapping = THREE.EquirectangularReflectionMapping;
    }
);

/**
 * Objects
 */

// Material

const options = {
    enableSwoopingCamera: false,
    enableRotation: true,
    transmission: 1,
    thickness: 2.9,
    roughness: 0,
    envMapIntensity: 0.8,
    clearcoat: 1,
    clearcoatRoughness: 0,
    clearcoatNormalScale: 0.3
  };

  gui.add(options, "transmission", 0, 1, 0.01).onChange((val) => {
    glassMaterial.transmission = val;
  });

  gui.add(options, "thickness", 0, 5, 0.1).onChange((val) => {
    glassMaterial.thickness = val;
  });

  gui.add(options, "roughness", 0, 1, 0.01).onChange((val) => {
    glassMaterial.roughness = val;
  });

  gui.add(options, "envMapIntensity", 0, 3, 0.1).onChange((val) => {
    glassMaterial.envMapIntensity = val;
  });

  gui.add(options, "clearcoat", 0, 1, 0.01).onChange((val) => {
    glassMaterial.clearcoat = val;
  });

  gui.add(options, "clearcoatRoughness", 0, 1, 0.01).onChange((val) => {
    glassMaterial.clearcoatRoughness = val;
  });

const glassMaterial = new THREE.MeshPhysicalMaterial({
    transmission: options.transmission,
    thickness: options.thickness,
    roughness: options.roughness,
    envMap: hdrEquirect,
    envMapIntensity: options.envMapIntensity,
    clearcoat: options.clearcoat,
    clearcoatRoughness: options.clearcoatRoughness
})

// Mesh
const loader = new GLTFLoader();
let coinGeometry = null
let coin = null

loader.load('models/OKTOken4.glb', function (gltf) {
    coinGeometry = (gltf.scene.children[0].geometry);
    coin = new THREE.Mesh(coinGeometry, glassMaterial)
    coin.rotation.x = Math.PI / 2
    coin.wireframe = true;
    scene.add(coin);
});

// const bgPlane = new THREE.Mesh(
//     new THREE.PlaneGeometry(5, 5), 
//     new THREE.MeshBasicMaterial
// )
// bgPlane.position.z = -5

// scene.add(bgPlane)

/**
 * Lights
 */
const directionalLight = new THREE.DirectionalLight('#ffffff', 1)
directionalLight.position.set(1, 1, 0)
scene.add(directionalLight)

/**
 * Particles
 */
// Geometry
const particlesGeometry = new THREE.BufferGeometry()
const count = 250

const positions = new Float32Array(count * 3) // Multiply by 3 because each position is composed of 3 values (x, y, z)

for(let i = 0; i < count * 3; i++) // Multiply by 3 for same reason
{
    positions[i] = (Math.random() - 0.5) * 10 // Math.random() - 0.5 to have a random value between -0.5 and +0.5
}

particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3)) // Create the Three.js BufferAttribute and specify that each information is composed of 3 values

// const particlesCount = 200
// const positions = new Float32Array(particlesCount * 3)

// for(let i = 0; i < particlesCount; i++)
// {
//     positions[i * 3 + 0] = (Math.random() - 0.5) * 100
//     positions[i * 3 + 1] = 2 - Math.random() * 40
//     positions[i * 3 + 2] = (Math.random() - 0.5) * 100
// }

// Material
const textureLoader = new THREE.TextureLoader()
const particleTexture = textureLoader.load('/OKTO-O.png')

const particlesMaterial = new THREE.PointsMaterial({
    map: particleTexture,
    transparent: true,
    sizeAttenuation: true,
    size: 0.2,
    alphaTest: false,
    blending: THREE.AdditiveBlending
})

// Points
const particles = new THREE.Points(particlesGeometry, particlesMaterial)
scene.add(particles)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Group
const cameraGroup = new THREE.Group()
scene.add(cameraGroup)

// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 6
cameraGroup.add(camera)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.outputColorSpace = THREE.LinearSRGBColorSpace
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

function animate() {
    if (coin) {
        // coin.rotation.x += 0.01;
        coin.rotation.y += 0.001;
        coin.rotation.z += 0.01;
    }
    requestAnimationFrame(animate);
    renderer.render(scene, camera);
}
animate();

// /**
//  * Interaction
//  */
window.addEventListener('click', () =>
{
    gsap.to(
        coin.rotation,
        {
            duration: 1.5,
            // x: '+=6',
            y: '+=3',
            z: '+=1.5'
        })
})

/**
 * Cursor
 */
const cursor = {}
cursor.x = 0
cursor.y = 0

window.addEventListener('mousemove', (event) =>
{
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5
})

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Animate camera
    camera.position.y = - scrollY / sizes.height

    const parallaxX = cursor.x * 0.5
    const parallaxY = - cursor.y * 0.5
    cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 5 * deltaTime
    cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 5 * deltaTime

    // Animate mesh
    // coin.rotation.x += deltaTime * 0.1
    // coin.rotation.y += deltaTime * 0.12

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()